@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,500;1,600&display=swap");

.process-top img {
  width: 100%;
  height: 650px;
  object-fit: cover;
}
.process-head {
  margin: 2rem 5rem;
  text-align: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(201, 180, 180, 0.605);
}
.process-head span {
  color: #038703;
}
.process-head h3 {
  font: normal bold 45px/1 "Sans";
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 4rem;
}
.process-head p {
  font: normal 26px/40px "Sans";
}
/* Process Main */

.process-main {
  margin-top: 5rem;
  margin-bottom: 4rem;
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(201, 180, 180, 0.605);

  gap: 6rem;
}
.process-left img {
  width: 950px;
  height: 1050px;
  border-radius: 15px;
}
.process-right {
  background-color: antiquewhite;
  padding: 50px;
  width: 750px;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.process-right h3 {
  font: normal bold 35px/1 "Sans";
  letter-spacing: 1px;
  text-transform: uppercase;
}
.process-right span {
  color: #038703;
}
.process-map {
  display: flex;
  align-items: center;
  gap: 10px;
}
.icon-process {
  color: #038703;
  font-size: 28px;
}
.process-map p {
  font: normal normal 26px/37px "Sans";
  letter-spacing: 0.5px;
  text-align: left;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .process-top img {
    width: auto;
  }
  .process-head {
    margin: 1rem;
  }
  .process-head h3 {
    font: normal normal bold 25px/1 "Sans";
    margin-top: 2rem;
  }
  .process-head p {
    font: normal 18px/36px "Sans";
  }
  .process-main {
    margin: 0;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .process-left {
    padding: 10px;
  }
  .process-left img {
    width: 100%;
    object-fit: cover;
  }
  .process-right {
    padding: 5px;
    margin: 5px;
  }
  .process-right h3 {
    font: normal bold 25px/40px "Sans";
    text-align: center;
  }
  .process-map p {
    font: normal normal 18px/25px "Sans";
  }
}
