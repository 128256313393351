@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,500;1,600&display=swap");

.product-top img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.product-content {
  margin: 3rem 15rem;
}
.product-content p {
  font: normal 26px/40px "Merriweather";
  text-align: center;
}
.product-img-flex img {
  width: 500px;
  height: 450px;
  object-fit: cover;
  margin-bottom: 50px;
  border-radius: 20px;
}
.product-img-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
}
.product-card-one {
  position: relative;
}
.product-caption {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  color: #1c470c;
  font: normal bold 33px/1 "merriweather";
  text-transform: uppercase;
  letter-spacing: 3px;
  opacity: 0;
  transition: all 0.5s ease;
}
.product-caption:hover {
  opacity: 1;
  border: 2px solid #336b24;
  transform: scale(1.1);
  border-radius: 10px;
}

/* Media Querry */

@media only screen and (max-width: 1060px) {
  .product-content {
    margin: 1rem;
  }
  .product-content p {
    font: normal normal 18px/36px "Merriweather";
    text-align: justify;
  }
  .product-img-flex {
    padding: 10px;
    gap: 2px;
  }
  .product-img-flex img {
    width: 100%;
    height: 200px;
    border-radius: 15px;
  }
}
