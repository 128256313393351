* {
  list-style: none;
  text-decoration: none;
}
.nav-logo-image {
  height: 60px;
  border-radius: 15px;
  object-fit: cover;
}
.navbar {
  display: flex;
}
.nav-top-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.nav-logo {
  font: normal normal bold 28px/1 "Sans";
  letter-spacing: 1px;
  text-transform: uppercase;
}
.nav-logo span {
  color: #038703;
}
.main-div {
  background: #ffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}
.navbar ul li {
  position: relative;
  float: left;
}
.navbar ul li a {
  font: normal 26px/1 "sans";
  margin-right: 55px;
  padding: 15px 35px;
  color: #333;
  display: block;
}
.navbar ul li a:hover {
  background: linear-gradient(to right, #336b24 0%, #1c470c 100%);
  color: #fff;
  border-radius: 10px;
}
.navbar ul li ul {
  position: absolute;
  left: 0;
  width: 200px;
  background: #fff;
  display: none;
}
.navbar ul li ul li {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.navbar ul li ul li ul {
  left: 200px;
  top: 0;
}
.navbar ul li:focus-within > ul,
.navbar ul li:hover > ul {
  display: initial;
}
#menu-bar {
  display: none;
}
.main-div label {
  font: normal 25px/1 "sans";
  color: #333;
  cursor: pointer;
  display: none;
}

@media (max-width: 1191px) {
  .nav-logo {
    font: normal bold 25px/30px "Sans";
  }
  .main-div label {
    display: initial;
  }
  .navbar {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: none;
  }
  .navbar ul li {
    width: 100%;
  }
  .navbar ul li ul {
    position: relative;
    width: 100%;
  }
  .navbar ul li ul li {
    background-color: #eee;
  }
  .navbar ul li ul li {
    width: 100%;
    left: 0;
  }
  #menu-bar:checked ~ .navbar {
    display: inline;
  }
}
