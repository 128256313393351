@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,500;1,600&display=swap");

.contact-top img {
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.contact-main {
  background: url(../Assets/Main-Background/wind.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  opacity: 0.8;
  z-index: 111111;
}

/* Contact Main */

.contact-flex {
  display: flex;
  justify-content: center;
  gap: 15rem;
  margin: 3rem 0;
}

/* Contact Left */

.contact-left {
  border: 3px solid green;
  width: fit-content;
  padding: 20px 190px;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.contact-left h3 {
  font: normal bold 44px/1 "Sans";
  color: black;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 4rem;
}

.contact-left input {
  width: 500px;
  padding: 20px 10px;
  border-radius: 5px;
  outline: none;
  border: 2px solid #e7e7e7;
  border: 2px solid #435c26;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.contact-left span,
.contact-right span {
  color: #038703;
}
.contact-div {
  margin-bottom: 2.5rem;
}
.contact-div p {
  font: normal 26px/1 "Sans";
  letter-spacing: 1px;
}
.submit-btn {
  padding: 30px;
  display: flex;
  justify-content: center;
}
.button {
  padding: 15px 50px;
  border: 2px solid #fff;
  outline: none;
  background: linear-gradient(to right, #2c7744 0%, #435c26 100%);
  color: white;
  font: normal bold 20px/1 "Sans";
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
}

::placeholder {
  color: black;
  font: normal normal 20px/1 "Sans";
  color: #435c26;
  letter-spacing: 1px;
}
.description {
  height: 120px;
}
/* Contact Right */

.contact-right {
  display: flex;
  justify-content: center;
}
.contact-right h3 {
  font: normal bold 45px/1 "Sans";
}
.contact-right-card {
  border: 3px solid green;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.contact-right-card2 {
  border: 3px solid green;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.contact-address {
  display: flex;
  align-items: center;
  gap: 2rem;
  text-align: justify;
}
.contact-address p {
  font: normal normal 25px/1 "Sans";
  letter-spacing: 0.5px;
}
.map-section iframe {
  height: 300px;
  width: 500px;
}
.map-section {
  display: flex;
  width: 100%;
}
.location-title {
  margin-top: 7rem;
}
.map-section iframe {
  width: 100%;
}

/* Media Querry */

@media only screen and (max-width: 1060px) {
  .contact-flex {
    flex-wrap: wrap;
    gap: 4rem;
  }
  .contact-left {
    padding: 25px;
  }
  .contact-left h3 {
    font: normal bold 35px/1 "Sans";
  }
  .contact-left input {
    width: 100%;
  }
  .contact-address p {
    font: normal 18px/30px "Sans";
  }
  .contact-right-card {
    padding: 10px;
  }
  .contact-right h3 {
    font: normal bold 29px/1 "Sans";
  }
  .contact-div {
    margin-bottom: 1rem;
  }
  .contact-div p {
    font: normal 20px/1 "Sans";
  }
}
