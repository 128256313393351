@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,500;1,600&display=swap");

.product-sub-top img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.product-sub-main h3 {
  font: normal bold 40px/1 "Sans";
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5rem;
  text-transform: uppercase;
}
.product-sub-main span {
  color: #038703;
}
.product-img-flex img {
  width: 440px;
  height: 440px;
  object-fit: cover;
  margin-bottom: 50px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
.product-img-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 5rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 2px solid #e7e7e7;
}
.product-img-flex p {
  margin: 0px 250px;
  text-align: center;
  font: normal normal 26px/40px "Sans";
}

/* Media Querry */

@media only screen and (max-width: 1060px) {
  .product-img-flex img {
    width: 100%;
  }
  .product-img-flex {
    gap: 0;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
  .product-sub-main h3 {
    font: normal bold 25px/40px "Sans";
  }
  .product-img-flex p {
    margin: auto;
    font: normal 22px/40px "Sans";
  }
}
