@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,500;1,600&display=swap");

.Footer {
  display: flex;
  justify-content: space-around;
  background: linear-gradient(to right, #336b24 0%, #1c470c 100%);
  padding-top: 65px;
  padding-bottom: 10px;
  flex-wrap: wrap;
  height: auto;
  opacity: 1;
  border-top: 2px solid #e7e7e7;
}
.footer-right-one {
  margin-top: 30px;
}
.Footer h4 {
  font: normal normal bold 30px/1 "Sans";
  text-transform: uppercase;
  margin-bottom: 32px;
  color: white;
  letter-spacing: 2px;
}
.Footer p {
  font: normal normal normal 26px/1 "Sans";
  color: white;
  line-height: 30px;
  letter-spacing: 0.5px;
}

.footer-right img {
  width: 35px;
  margin-right: 10px;
}
.social-icons {
  color: white;
  font-size: 30px;
  padding: 10px;
  border: 2px solid white;
  border-radius: 50px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.6s all ease-in-out;
}
.social-links :hover {
  transform: scale(1.1);
  background-color: black;
}
.social-links {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
/*  Media Querry */

@media only screen and (max-width: 760px) {
  .Footer {
    text-align: center;
  }
  .Footer h4 {
    font: normal normal bold 28px/1 "Sans";
  }
  .Footer p {
    font: normal normal normal 22px/30px "Sans";
  }
  .footer-left {
    margin-bottom: 20px;
  }
  .footer-right {
    margin-top: 20px;
  }
}
