@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,500;1,600&display=swap");

.home-img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  z-index: 1000;
}

/* Home Second Section */

.home-second-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  padding-bottom: 5rem;
  margin-top: 3rem;
  border-bottom: 2px solid #038703;
}
.home-second-left h3 {
  font: normal bold 45px/1 "Sans";
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.605);
  text-align: center;
}
.home-second-left span {
  color: #038703;
}
.home-second-left p {
  width: 750px;
  font: normal normal 26px/45px "Sans";
  text-align: center;
}
.home-second-right {
  background: linear-gradient(to right, #336b24 0%, #1c470c 100%);
  width: 750px;
  height: 600px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal 45px/80px "Sans";
  letter-spacing: 2px;
  color: white;
}
.home-second-right h3 {
  font: normal normal bold 75px "Sans";
  text-transform: capitalize;
}
.home-img-bottom {
  width: 1050px;
  object-fit: cover;
  height: 750px;
  border-radius: 24px;
}

/* Home third Section */

.home-third-section {
  display: flex;
  justify-content: space-between;
  margin: 5rem 3rem;
  gap: 50px;
  padding-bottom: 5rem;
  border-bottom: 2px solid #038703;
}
.home-third-right {
  border: 3px solid green;
  border-radius: 12px;
  padding: 0px 24px;
}
.main-title {
  font: normal normal bold 40px/1 "sans";
  letter-spacing: 2px;
  color: green;
  text-transform: uppercase;
  text-align: center;
}
.home-third-right .name-tag-one {
  color: rgba(0, 0, 0, 0.605);
}
.home-third-right h2 {
  font: normal normal bold 40px/1 "sans";
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.605);
  text-transform: uppercase;
  text-align: center;
}
.name-tag {
  color: green;
}
.sub-head {
  font: normal 30px/1 "Sans";
  color: rgba(0, 0, 0, 0.605);
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.sub-content {
  font: normal 25px/38px "Sans";
  text-align: left;
}
.product-detail {
  margin-top: 5rem;
}

/* Home Testimonial Section */

.testimonial-section {
  background: linear-gradient(to right, #2c7744 0%, #435c26 100%);
  padding: 30px;
  border-radius: 150px;
  margin: 10rem;
  color: white;
}
.test-card-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-around;
}
.test-icon {
  font-size: 60px;
  color: whitesmoke;
}
.test-count {
  font: normal bold 28px/1 "Sans";
  letter-spacing: 1px;
}
.test-head {
  font: normal bold 26px/1 "sans";
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Media Querry */

@media only screen and (max-width: 1200px) {
  .home-third-section {
    flex-wrap: wrap;
    margin: 1rem 1rem;
  }
  .home-img-bottom {
    width: 100%;
    height: 300px;
  }
  .home-second-section {
    gap: 2rem;
  }
  .home-second-left p {
    width: 100%;
    font: normal 18px/36px "Sans";
    text-align: justify;
  }
  .home-second-left {
    padding: 0 10px;
  }
  .home-second-right {
    font: normal bold 30px/45px "Sans";
  }
  .home-third-right {
    text-align: center;
    padding: 0px 12px;
  }
  .home-third-right h2 {
    font: normal bold 28px/35px "Sans";
  }
  .main-title {
    font: normal bold 28px/35px "Sans";
  }
  .sub-head {
    font: normal normal 22px/1 "sans";
  }
  .product-detail {
    margin-top: 2rem;
  }
  .testimonial-section {
    margin: 2rem;
    text-align: center;
  }
  .sub-content {
    font: normal 18px/26px "sans";
  }
  .test-icon {
    font-size: 40px;
  }
  .test-head {
    font: normal bold 20px/25px "Sans";
  }
  .test-count {
    font: normal bold 20px/1 "Sans";
  }
}
