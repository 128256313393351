@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,500;1,600&display=swap");

.about-top img {
  width: 100%;
  height: 650px;
  object-fit: cover;
}
.about-top-head {
  margin: 4rem;
  border-bottom: 1px solid rgba(201, 180, 180, 0.605);
}
.about-title {
  font: normal bold 42px/1 "Sans";
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  /* margin-bottom: 4rem; */
}
.about-top-head span {
  color: #038703;
}
.about-title span {
  color: #038703;
}
.about-top-head p {
  font: normal normal 26px/40px "Sans";
  text-align: center;
  margin-bottom: 4rem;
}

/* Mission Section */

.mission-section {
  margin: 5rem;
  display: flex;
  justify-content: center;
  gap: 5rem;
}
.mission-left {
  width: 950px;
}
.mission-right {
  background-color: #e7e7e7;
  padding: 50px 30px;
  border-radius: 14px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.mission-head-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.about-sub-head {
  font: normal 29px/1 "sans";
}
.mission-left p {
  font: normal normal 26px/40px "Sans";
  text-align: left;
}
.mission-right h3 {
  font: normal bold 37px/40px "Sans";
  color: #038703;
  text-align: center;
  text-transform: capitalize;
  margin-top: -1px;
  text-transform: uppercase;
}
.mission-right h4 {
  font: normal bold 27px/1 "Sans";
  letter-spacing: 1px;
  margin-top: 50px;
  color: black;
  text-align: left;
}
.mission-right p {
  font: normal normal 26px/1 "Sans";
  text-align: left;
}

/* Management */

.management {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  margin: 0;
}
.management-main-title {
  font: normal bold 45px/1 "Merriweather";
  text-align: center;
  letter-spacing: 1px;
  margin: 7rem 0;
  text-transform: uppercase;
}
.management-card {
  width: 480px;
  background-color: #336b24;
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  border-radius: 10px;
  margin-bottom: 5rem;
}
.management-card img {
  width: 450px;
  height: 550px;
  object-fit: cover;
  padding: 15px;
}
.owner-name {
  font: normal bold 22px/1 "Merriweather";
  letter-spacing: 2px;
  text-transform: uppercase;
}
.owner-position {
  font: normal bold 24px/1 "Merriweather";
  letter-spacing: 2px;
}

/* Media Querry */
@media only screen and (max-width: 760px) {
  .about-title {
    font: normal normal bold 28px/50px "Sans";
  }
  .about-top-head {
    margin: 1rem;
  }
  .about-top-head p {
    font: normal normal 18px/36px "Sans";
    text-align: justify;
  }
  .about-sub-head {
    font: normal 29px/40px "Sans";
  }
  .mission-section {
    gap: 1rem;
    flex-wrap: wrap;
    margin: auto;
  }
  .mission-left {
    padding: 10px;
  }
  .mission-left p {
    font: normal normal 18px/36px "Sans";
    text-align: justify;
  }
  .mission-right {
    padding: 25px 15px;
    margin: 10px;
  }
  .mission-right h3 {
    font: normal bold 25px/1 "Sans";
  }
  .mission-right h4 {
    font: normal bold 25px/1 "Sans";
  }
  .mission-right p {
    font: normal 22px/44px "Sans";
  }
  .management {
    gap: 0px;
    margin: 10px;
  }
  .management-div {
    width: 100%;
  }
  .management-div img {
    width: 350px;
    height: auto;
  }
  .management-card {
    padding: 0;
  }
  .management-main-title {
    font: normal bold 25px/1 "Merriweather";
    margin: 3rem 0;
  }
}
